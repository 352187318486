export const useUserStore = () => {
  const runtimeConfig = useRuntimeConfig()
  const tokenCookie = useCookie('token')
  const stateKey = 'userLoginState'
  const state = useState(stateKey, () => {
    return {
      user_id: null,
      nickname: null,
      expires_at: null,
      token: null,
    }
  })

  const loginHandler = async function(login, password) {
    const {  data, error } = await useFetch(`${runtimeConfig.public.apiBase}/pub/signin`, {
      method: 'POST',
      body: { login: login, password: password },
    });
    if (error.value) {
      return false
    } else {
      state.value.user_id    = data.value.payload.user_id
      state.value.nickname   = data.value.payload.nickname
      state.value.expires_at = data.value.payload.exp
      state.value.token      = data.value.payload.token
      tokenCookie.value = state.value.token
      return true
    }
  }

  const logoutHandler = async function() {
    state.value.user_id = null
    state.value.nickname = null
    state.value.expires_at = null
    state.value.token = null
    tokenCookie.value = null
  }

  const restoreState = function() {
    if (tokenCookie.value && process.client) {
      const base64Url = tokenCookie.value.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const json = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      const decoded = JSON.parse(json);
      if (decoded.exp > Date.now() / 1000) {
        state.value.token = tokenCookie.value
        state.value.user_id = decoded.user_id
        state.value.nickname = decoded.nickname
        state.value.expires_at = decoded.exp
      } else {
        state.value.token = null
        state.value.user_id = null
        state.value.nickname = null
        state.value.expires_at = null
        tokenCookie.value = null
      }
    }
  }

  const isLoggedIn = function() {
    if (!state.value.user_id) {
      restoreState()
    }
    return (state.value.user_id)
  }

  const getNickname = function() {
    if (!state.value.nickname) {
      restoreState()
    }
    return (state.value.nickname) ? state.value.nickname : null
  }
  const getUserId = function() {
    if (!state.value.user_id) {
      restoreState()
    }
    return (state.value.user_id) ? state.value.user_id : null
  }

  const getToken = function() {
    if (!state.value.token) {
      restoreState()
    }
    return (state.value.token) ? state.value.token : null
  }

  return {
    loginHandler,
    logoutHandler,
    isLoggedIn,
    getNickname,
    getUserId,
    getToken,
  }
};